import * as React from 'react';
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { setTrackingCookie } from '@beelineloans/cx-library/src/utils/helpers';
import linkResolver from '@beelineloans/cx-library/src/utils/prismic/linkResolver';
import { StateProvider } from '@beelineloans/cx-library/src/state/stateProvider';
import { pushCrispChat } from '@beelineloans/cx-library/src/utils/dataLayer';
import { isInsideBusinessHours } from '@beelineloans/cx-library/src/utils/time';
import Page from './src/templates/page';
import Article from './src/templates/article';
import LoanGuide from './src/templates/loanGuide';

const onRouteUpdate = () => {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    const data = window.dataLayer || [];
    const path = document.location.pathname;
    const event = `route-change-${path}`;

    data.push({
      event,
      route: document.location.pathname
    });
  }, 50);
};

const onClientEntry = () => {
  const insideBusinessHours = isInsideBusinessHours();
  console.info(insideBusinessHours);
  console.info(process.env.GATSBY_PROD);
  const isYoutubePage = document.location.pathname.includes('/refinance/cashout-youtube');

  const workspaceId = process.env.GATSBY_PROD === 'true' ? 'ws_r_1x7dz8GMoVELx' : 'ws_JKDWCRP69CZUGix';
  const experienceId = process.env.GATSBY_PROD === 'true' ? 'e_4yDa6oD9KnwdSAd' : 'e_CaZjQNVwrc8y9Ah';
  if (window && isYoutubePage) {
    const script = window.document.createElement('script');
      script.id = 'magicblocks-chatbot-script';
      script.type = 'text/javascript';
      script.innerHTML = `
          window.MAGICBLOCKS = {
          workspace: '${workspaceId}',
          experience: '${experienceId}',
          config: {
            style: 'dynamic',
            open: true,
          }
        };

        (function () {
          var queryPath = '?ws=' + window.MAGICBLOCKS.workspace + '&e=' + window.MAGICBLOCKS.experience;
          var h = document.getElementsByTagName('body')[0];
          var s = document.createElement('script');
          s.id = 'mbw-script';
          s.type = 'text/javascript';
          s.src = 'https://scripts.magicblocks.com/widget/1.0.0' + queryPath;
          h.appendChild(s);
        })();
      `;
      window.document.body.appendChild(script);
  } else if (window) {
    const context = {
      kind: 'user',
      anonymous: true,
      key: 'anonymous-default'
    };

    const client = LDClient.initialize(process.env.GATSBY_LD_CLIENT_ID, context);
    client
      .waitForInitialization()
      .then(() => {
        // initialization succeeded, flag values are now available
        const enableBobAllTime = client.variation('website-enable-bob-chat', false);
        const enableBobOutBusinessHours = client.variation('website-enable-bob-out-business-hours', true);
        const autoOpenBob = client.variation('web-enable-bob-on-youtube-page', false) && isYoutubePage;
        if (enableBobAllTime || (enableBobOutBusinessHours && !insideBusinessHours)) {
          const script = window.document.createElement('script');
          script.id = 'magicblocks-chatbot-script';
          script.type = 'text/javascript';
          script.innerHTML = `
             window.MAGICBLOCKS = {
              workspace: '${workspaceId}',
              experience: '${experienceId}',
              config: {
                style: 'dynamic',
                open: ${autoOpenBob},
              }
            };

            (function () {
              var queryPath = '?ws=' + window.MAGICBLOCKS.workspace + '&e=' + window.MAGICBLOCKS.experience;
              var h = document.getElementsByTagName('body')[0];
              var s = document.createElement('script');
              s.id = 'mbw-script';
              s.type = 'text/javascript';
              s.src = 'https://scripts.magicblocks.com/widget/1.0.0' + queryPath;
              h.appendChild(s);
            })();
          `;

          window.document.body.appendChild(script);
        } else {
          pushCrispChat({ enableBobAllTime: false, enableBobOutBusinessHours: false });
        }
      })
      .catch(() => {
        // initialization failed
        pushCrispChat({ enableBobAllTime: false, enableBobOutBusinessHours: false });
      });

    window.addEventListener('gtmCustomEvent', (e) => {
      if (e.detail && e.detail.event) {
        if (e.detail.event === 'redTrackClickID') {
          setTrackingCookie(`?rtkcid=${e.detail.data}&rtkcmpid=${e.detail.campaign}`);
        }
      }
    });
  } else {
    pushCrispChat(false);
  }
  setTrackingCookie();
};

const wrapRootElement = ({ element }) =>
  process.env.GATSBY_PROD === 'true' ? (
    <StateProvider>{element}</StateProvider>
  ) : (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            article: Article,
            loan_guide: LoanGuide
          })
        }
      ]}
    >
      <StateProvider>{element}</StateProvider>
    </PrismicPreviewProvider>
  );

export { onClientEntry, onRouteUpdate, wrapRootElement };
