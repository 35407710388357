const linkResolver = (doc) => {
  if (!doc || !doc.uid) return '/';

  const path = doc.uid.toLowerCase();

  // URL for a blog type
  if (doc.type === 'article') {
    return `/blog/${path}/`;
  }

  // URL for a blog category type
  if (doc.type === 'category') {
    return `/blog/category/${path.replace(/&/g, 'and').replace(/ /g, '-')}/`;
  }

  // URL for a page type
  if (doc.type === 'page') {
    const pagePath = `/${doc.data?.folder ? `${doc.data.folder.toLowerCase()}/` : ''}${path}/`;
    return pagePath;
  }

  if (doc.type === 'loan_guide') {
    return `/loan-guides/${doc.uid}/`;
  }

  // Backup for all other types
  return '/';
};

// exports.linkResolver = linkResolver;
module.exports = linkResolver;
// export default linkResolver;
