/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import { NavOnlyHeader } from '@beelineloans/cx-library/src/components/layout/headersv2';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { H2, H3, Headline, Paragraph, SubHeadline, Span } from '@beelineloans/cx-library/src/components/typography';
import { Button, ButtonOutline, ButtonText } from '@beelineloans/cx-library/src/components/buttons';
import constants from '@beelineloans/cx-library/src/utils/constants';
import { TextAndImageV2, ReviewsWithSocial } from '@beelineloans/cx-library/src/components/layout/panels';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import QuoteImage from '@beelineloans/cx-library/src/images/icons/quote-white.png';
import { CAROUSEL_TYPES } from '@beelineloans/cx-library/src/components/Carousel';
import { PlantAndCoffee, Image } from '@beelineloans/cx-library/src/components/prismic-slices';
import { addCountryCode, cleanDisplayPhoneNumber, setTrackingCookie } from '@beelineloans/cx-library/src/utils/helpers';
import KeysImg from '@beelineloans/cx-library/src/images/keys.png';

const linkResolver = require('../../linkResolver');

const HeadShot = styled.div`
  width: calc(100% + 30px);
  margin-left: -15px;
  height: 450px;
  background-image: url('${(props) => props.$image}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin-left: 0;
    width: 65%;
    transform: translateX(-7.5%);
    position: absolute;
    height: 100%;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    width: 75%;
    transform: translateX(-12.5%);
  }

  @media only screen and (min-width: 1440px) {
    width: 100%;
    position: initial;
    background-size: contain
    transform: translateX(0);
  }
`;

const Meet = styled(Container)`
  .container-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      flex-direction: row;
    }
  }
`;

const Half = styled.div`
  width: 100%;
  &:first-of-type {
    padding-top: 75px;
    padding-bottom: 30px;
  }
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    width: 50%;
    &:first-of-type {
      padding-top: 90px;
      padding-bottom: 90px;
      padding-right: 15px;
      box-sizing: border-box;
      background-color: ${(props) => props.theme.colours.background.cream};
      z-index: 10;
    }
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    &:first-of-type {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  @media only screen and (min-width: 1440px) {
    &:last-of-type {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: space-between;
  max-width: 454px;
  margin-top: 30px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin-top: 0px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a {
    @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
      &:first-of-type {
        margin-bottom: 30px;
      }
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      width: 217px;
    }
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin: 0;
    flex-direction: row;
  }
`;

const MeetParagraph = styled(Paragraph)`
  margin-top: 20px !important;
  text-align: left;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    text-align: center;
  }
`;

const NameLocation = styled(SubHeadline)`
  margin-top: 30px;
`;

const QuoteImg = styled.img`
  margin-bottom: 37px;
`;

const AboutParagraph = styled(Paragraph)`
  position: relative;
  z-index: 30;
`;

const AboutSection = styled(TextAndImageV2)`
  .image-wrap {
    text-align: left;
    img {
      position: relative;
      z-index: 30;
    }
  }
  @media only screen and (${(props) => props.theme.mediaBreakpoints.small}) {
    padding-bottom: 70px;
  }
`;

const ProfileContent = styled.div`
  max-width: 550px;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 50px;
`;

const ImageWrap = styled.div``;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left !important;
`;

const Name = styled(H3)`
  font-size: 35px;
  line-height: 35px;
`;

const NameAndImage = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left !important;
  justify-content: space-between;
  max-width: 380px;
  width: 100%;
  align-items: center;
`;

const ProfileImage = styled(Image)`
  border-radius: 50%;
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 550px;
`;

const Row = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 16px;
  text-align: left;

  span {
    text-align: left;
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    flex-direction: row;
  }
`;

const Title = styled(Span)`
  font-size: 25px;
  display: inline-block;
  width: 120px;
  text-align: left;
  margin-bottom: 10px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin-bottom: 0px;
  }
`;

const ChatButtonWrap = styled.div`
  text-align: left !important;
  margin: 30px 0 50px;
  a,
  button {
    width: 217px;
  }
`;

const LGCoffee = styled(PlantAndCoffee)`
  .container-wrap {
    background-position: right bottom;
    padding-bottom: 100px;

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      padding-bottom: 0;
      background-position: right center;
    }
  }
`;

const Keys = styled.img`
  position: absolute;
  right: -62px;
  bottom: -111px;
  z-index: 20;
  width: 250px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
    right: -95px;
    bottom: -170px;
    width: 377px;
  }
`;

const LoanGuide = ({ data, pageContext }) => {
  const document = data.prismicLoanGuide;
  // const { canonical, overridePath } =  pageContext;
  const { canonical, reviews } = pageContext;
  const path = linkResolver(data.prismicLoanGuide);
  const loanGuide = data.prismicLoanGuide.data;
  const email = loanGuide.email ?? constants.LINKS.EXTERNAL.EMAIL.DEFAULT;
  const phone = loanGuide.phone ?? constants.LINKS.EXTERNAL.PHONE.DOTS;
  // const user = email && email.indexOf('@') > -1 ? email.split('@')[0] : undefined;

  if (!document) navigate(constants.LINKS.MAIN.LOAN_GUIDE);

  const bookACallURL = loanGuide.calendar_url_id ? `https://calendly.com/${loanGuide.calendar_url_id}` : 'https://calendly.com/loan-guides/';
  const applyUrl = loanGuide.apply_url_id ? `https://go.blne.us/${loanGuide.apply_url_id}_hive` : constants.LINKS.EXTERNAL.POS.APPLY;
  let lgReviews = reviews ? reviews.filter((r) => r.data?.loan_guide?.document?.data?.email?.toLowerCase() === email?.toLowerCase()) : undefined;
  lgReviews = lgReviews && lgReviews.length > 0 ? lgReviews.slice(0, 3) : undefined;

  if (lgReviews && reviews && lgReviews.length < 3) {
    lgReviews = [...lgReviews, ...reviews.filter((r) => !r.data?.loan_guide?.document?.data?.email).slice(0, 3 - lgReviews.length)];
  }

  const reviewItems = (lgReviews ?? (reviews ? reviews.slice(0, 3) : [])).map((r) => {
    const rev = {
      type: CAROUSEL_TYPES.REVIEW,
      data: {
        ...r.data,
        stars: r.data?.star_rating
      }
    };
    return rev;
  });

  useEffect(() => {
    setTrackingCookie(`?utm_source=WOM&utm_medium=lg_page&utm_campaign=${loanGuide.apply_url_id}`);
  }, []);

  return (
    <Template headerComponent={NavOnlyHeader}>
      <SEO path={path} title="Loan Guide" description="Loan Guide" canonical={canonical} />
      <Meet>
        <Half>
          <Headline>
            Meet your Beeline
            <br />
            Loan Guide
          </Headline>
          <NameLocation>
            <b>
              {loanGuide.first_name} {loanGuide.last_name}
            </b>
            <br />
            {loanGuide.location}
          </NameLocation>
          <ButtonWrap>
            <ButtonGroup>
              <Button to={applyUrl}>Apply in 15 minutes</Button>
              <ButtonOutline to={bookACallURL}>Book a call</ButtonOutline>
            </ButtonGroup>
            <MeetParagraph center>{loanGuide.first_name} will be your dedicated Beeline Loan Guide.</MeetParagraph>
          </ButtonWrap>
        </Half>
        <Half>
          <HeadShot src={loanGuide.head_shot.url} $image={loanGuide.head_shot.url} />
        </Half>
      </Meet>
      {/* h2, h3, imgH2, imgH3, children, image, backgroundColor, left, centerText, verticalCenterText, */}
      <AboutSection
        backgroundColor={Theme.colours.background.green}
        image={{
          src: loanGuide.about_image.url,
          mobileWidth: '315px',
          desktopWidth: '540px',
          alignment: 'center'
        }}
        left
      >
        <QuoteImg src={QuoteImage} width="66" height="50" alt={loanGuide.about_title} />
        <H2 light>{loanGuide.about_title}</H2>
        <AboutParagraph light>{loanGuide.about.text}</AboutParagraph>
        <Keys src={KeysImg} />
      </AboutSection>
      {reviewItems.length > 0 && (
        <ReviewsWithSocial
          showRating={false}
          title={lgReviews && lgReviews.length > 0 ? `${loanGuide.first_name}'s reviews.` : 'Love letters.'}
          text={
            lgReviews && lgReviews.length > 0
              ? `See why people loved having ${loanGuide.first_name} as their Beeline Loan Guide.`
              : 'What people say about how we helped them to that sweet, sweet closing day.'
          }
          items={reviewItems}
        />
      )}
      <LGCoffee hidePlant showScheduleButton={false} showApplyButton={false}>
        <ProfileContent>
          <Profile>
            <NameAndImage>
              <Name light>
                {loanGuide.first_name}
                <br />
                {loanGuide.last_name}
              </Name>
              {loanGuide.profile_image && loanGuide.profile_image.url && (
                <ImageWrap>
                  <ProfileImage image={{ url: loanGuide.profile_image.url, dimensions: { width: 110, height: 110 }, alt: `${loanGuide.first_name} ${loanGuide.last_name}` }} />
                </ImageWrap>
              )}
            </NameAndImage>
            <ChatButtonWrap>
              <Button to={bookACallURL}>Book a call</Button>
            </ChatButtonWrap>
            <ProfileDetails>
              <Grid>
                <Row>
                  <Title light bold>
                    Phone
                  </Title>
                  <Span light>
                    <ButtonText dark={false} to={`tel:${addCountryCode(phone)}`}>
                      {cleanDisplayPhoneNumber(phone)}
                    </ButtonText>
                  </Span>
                </Row>
                <Row>
                  <Title light bold>
                    Email
                  </Title>
                  <Span light>
                    <ButtonText dark={false} to={`mailto:${email}`}>
                      {email}
                    </ButtonText>
                  </Span>
                </Row>
              </Grid>
            </ProfileDetails>
          </Profile>
        </ProfileContent>
      </LGCoffee>
    </Template>
  );
};

LoanGuide.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
};

export const query = graphql`
  query LoanGuideQuery($uid: String!) {
    prismicLoanGuide(lang: { eq: "en-us" }, uid: { eq: $uid }) {
      _previewable
      data {
        about_title
        email
        first_name
        last_name
        location
        phone
        position
        apply_url_id
        calendar_url_id
        head_shot {
          alt
          url
          dimensions {
            height
            width
          }
        }
        profile_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        about_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        about {
          html
          richText
          text
        }
      }
      uid
      type
    }
  }
`;

export default withPrismicPreview(LoanGuide);
