import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { htmlSerializer } from '@beelineloans/cx-library/src/utils/prismic/htmlSerializer';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import SliceZone from '@beelineloans/cx-library/src/components/prismic-slices/SliceZone';
import PostMeta from '@beelineloans/cx-library/src/components/PostMeta';
import { getArticleCategories } from '@beelineloans/cx-library/src/utils/prismic/prismicUtils';
import { resolveTemplate } from '@beelineloans/cx-library/src/utils/prismic/templateResolver';
import CategoryButtonList from '@beelineloans/cx-library/src/components/buttons/prismic/CategoryButtonList';
import Image from '@beelineloans/cx-library/src/components/prismic-slices/Image';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import AboutAuthor from '@beelineloans/cx-library/src/components/AboutAuthor';
import SocialShare from '@beelineloans/cx-library/src/components/SocialShare';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { Button } from '@beelineloans/cx-library/src/components/buttons';

const linkResolver = require('../../linkResolver');

const PageWrap = styled(Container)`
  .container-inner {
    margin: 0 auto;
    padding-top: 35px;
    padding-bottom: 80px;

    h1 {
      color: ${(props) => props.categoryColor};
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
      max-width: 950px;
    }

    @media only screen and (${(props) => props.theme.mediaBreakpoints.desktop}) {
      max-width: 1010px;
    }
  }
`;

const Categories = styled(CategoryButtonList)`
  padding-bottom: 20px;
`;

const Meta = styled(PostMeta)`
  padding-top: 0;
  padding-bottom: 30px;
`;

const Social = styled(SocialShare)`
  margin-top: 60px;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    margin-top: 80px;
  }
`;

const ArticleImage = styled(Image)`
  margin-top: 40px;
  margin-bottom: 50px;
`;

const panelToDocument = (panel, document, categories) => ({
  uid: document.uid,
  categories,
  data: {
    body: [
      {
        slice_type: 'converted_panels',
        primary: {
          ...panel
        }
      }
    ]
  }
});

const Article = ({ data, pageContext }) => {
  const document = data.prismicArticle;
  if (!document) return null;

  let articles = data.allPrismicArticle.nodes;
  const relatedArticle = data.allPrismicRelatedArticles?.nodes.length > 0 ? data.allPrismicRelatedArticles.nodes[0] : null;
  const ctaPanel = data.allPrismicFooterCtaPanel?.nodes.length > 0 ? data.allPrismicFooterCtaPanel.nodes[0] : null;
  const allCategories = data.allPrismicBlogCategory?.nodes;
  const subscribe = data.allPrismicSubscribe?.nodes.length > 0 ? data.allPrismicSubscribe.nodes[0] : null;
  const categories = getArticleCategories(document); // document.categories && document.categories.length > 0 ? document.categories[0].category1.name : null;
  const title = document.data.title ? document.data.title.text : document.data.browser_title;
  const description = document.data.meta_description ? document.data.meta_description : document.data.intro;
  const articleImage = (document.data.image || {}).url;
  const { overridePath } = pageContext;
  const path = overridePath || linkResolver(data.prismicPage);
  const categoryColour = categories.length > 0 ? (categories[0] ? categories[0].data.button_color : Theme.colours.text.dark) : Theme.colours.text.dark;
  articles = articles.filter((p) => p.uid !== 'required');

  const ArticleTemplate = resolveTemplate(document.type);

  return (
    <ArticleTemplate sideNavLinksComponent={false}>
      <SEO path={path} title={title} description={description} useTitleForSocial imageURL={articleImage} />
      <PageWrap categoryColor={categoryColour}>
        <Categories allCategories={allCategories} selected={categories.map((c) => (c ? c.data.name : null))} />
        {document.data.title && <RichText render={document.data.title.richText} htmlSerializer={htmlSerializer} />}
        <ArticleImage image={{ ...document.data.image, dimensions: { width: document.data?.image?.dimensions?.width } }} />
        <Meta
          showAuthor
          author={document.data.author}
          published={document.data.publish_date ? document.data.publish_date : document.first_publication_date}
          readTime={document.data.read_time}
        />
        {document.data.external_url?.url && (
          <Container center>
            <Button to={document.data.external_url?.url} newWindow>
              Read the full article
            </Button>
          </Container>
        )}
        <SliceZone document={document} articles={articles} />
        <Social title={title} shareUrl={path} description={description} imageUrl={document.data.image ? document.data.image.url : null} />
        {document.data.show_about_the_author && <AboutAuthor author={document.data.author.document} />}
      </PageWrap>
      {document.data.show_related_articles && relatedArticle && <SliceZone document={panelToDocument(relatedArticle, document, categories)} articles={articles} path={path} />}
      {document.data.show_related_articles && subscribe && <SliceZone document={panelToDocument(subscribe, document, categories)} articles={articles} />}
      {document.data.show_footer_cta && ctaPanel && <SliceZone document={panelToDocument(ctaPanel, document, categories)} articles={articles} />}
    </ArticleTemplate>
  );
};

Article.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
};

// see if we can change this query of allArticles to reference category directly so we don't need to grab unnecessary items and filter them later
export const query = graphql`
  query ArticleQuery($uid: String!) {
    prismicArticle(uid: { eq: $uid }, lang: { eq: "en-us" }) {
      _previewable
      last_publication_date(formatString: "")
      first_publication_date(formatString: "")
      uid
      data {
        intro
        meta_description
        publish_date(formatString: "")
        read_time
        show_about_the_author
        show_footer_cta
        show_related_articles
        external_url {
          url
        }
        title {
          html
          richText
          text
        }
        image {
          url
          dimensions {
            height
            width
          }
          alt
        }
        categories {
          category1 {
            document {
              ... on PrismicBlogCategory {
                id
                data {
                  button_color
                  name
                }
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                firstname
                lastname
                about
                profile_image {
                  url
                  uid
                  type
                  slug
                  size
                  target
                  link_type
                }
              }
            }
          }
        }
        body {
          ... on PrismicArticleDataBodyCta {
            id
            slice_label
            slice_type
            primary {
              button_text
              horizontal_alignment
              vertical_padding
              button_link {
                url
                uid
                target
                slug
                link_type
              }
            }
          }
          ... on PrismicArticleDataBodySpacer {
            id
            slice_type
            slice_label
          }
          ... on PrismicArticleDataBodyHtml {
            id
            slice_type
            slice_label
            primary {
              code
            }
          }
          ... on PrismicArticleDataBodyImage {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicArticleDataBodyStandout {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                copyright
                url
                dimensions {
                  height
                  width
                }
              }
              text {
                html
                richText
                text
              }
              title {
                html
                richText
                text
              }
            }
          }
          ... on PrismicArticleDataBodyVideo {
            slice_label
            slice_type
            primary {
              background_colour
              id
              title {
                html
                richText
                text
              }
              video {
                uri
                html
                title
                width
                height
                description
                embed_url
              }
            }
          }
          ... on PrismicArticleDataBodyQuote {
            id
            slice_label
            slice_type
            primary {
              text {
                html
                richText
                text
              }
            }
          }
          ... on PrismicArticleDataBodyContentBlock {
            id
            slice_label
            slice_type
            primary {
              content {
                html
                richText
                text
              }
            }
          }
        }
      }
      type
      lang
    }
    allPrismicArticle(sort: { fields: [first_publication_date], order: DESC }, limit: 30, filter: { lang: { eq: "en-us" } }) {
      nodes {
        last_publication_date(formatString: "")
        uid
        type
        first_publication_date(formatString: "")
        url
        data {
          read_time
          show_about_the_author
          show_footer_cta
          show_related_articles
          publish_date(formatString: "")
          meta_description
          intro
          external_url {
            url
          }
          categories {
            category1 {
              document {
                ... on PrismicBlogCategory {
                  id
                  data {
                    button_color
                    name
                  }
                }
              }
            }
          }
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  firstname
                  lastname
                  profile_image {
                    url
                    type
                    target
                    slug
                    uid
                    size
                  }
                }
              }
            }
          }
          image {
            url
            dimensions {
              height
              width
            }
            alt
          }
          title {
            html
            richText
            text
          }
        }
      }
    }
    allPrismicBlogCategory {
      nodes {
        data {
          button_color
          name
        }
      }
    }
    allPrismicRelatedArticles(filter: { prismicId: { eq: "XvGm0RIAAEuK7Hez" } }) {
      nodes {
        data {
          title {
            html
            richText
            text
          }
          notes
        }
        id
        url
        type
      }
    }
    allPrismicFooterCtaPanel(filter: { prismicId: { eq: "XvGmvBIAAEuK7HdS" } }) {
      nodes {
        type
        id
        url
        first_publication_date(formatString: "")
        last_publication_date(formatString: "")
        data {
          apply_button_text
          notes
          show_apply_button
          show_schedule_button
          schedule_button_text
          title {
            html
            richText
            text
          }
          supporting_text {
            html
            richText
            text
          }
          schedule_button_link {
            url
            uid
            type
            target
            slug
            link_type
          }
          apply_button_link {
            link_type
            url
            uid
            target
            slug
          }
        }
      }
    }
    allPrismicSubscribe(filter: { prismicId: { eq: "XvGm6RIAADQy7Hgi" } }) {
      nodes {
        data {
          button_text
          title {
            html
            richText
            text
          }
        }
        url
        id
        type
        lang
      }
    }
  }
`;
export default withPrismicPreview(Article);
